import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import image1 from "../../assets/1.webp";
import image2 from "../../assets/2.webp";
import image3 from "../../assets/3.webp";
import image4 from "../../assets/4.webp";
import image5 from "../../assets/5.webp";
import image6 from "../../assets/6.webp";

function ServicesOnPage() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const services = [
    {
      image: image1,
      title: "Löpande bokföring",
      description:
        "Hjälper dig med löpande bokföring och säkerställer en smidig och effektiv rutin för rapportering.",
      details:
        "LD Ekonomi & Konsult erbjuder fullständig transparens och flexibilitet för att stödja ditt företags tillväxt. Med detaljerad redovisning hjälper LD Ekonomi & Konsult dig att spara tid och undvika onödiga fel.",
      category: "Finance",
    },
    {
      image: image2,
      title: "Skattedeklaration",
      description:
        "Hanterar alla typer av deklarationer och säkerställer att de sköts korrekt.",
      details:
        "LD Ekonomi & Konsult säkerställer att din deklaration uppfyller alla juridiska krav och hanteras i rätt tid.",
      category: "Tax",
    },
    {
      image: image3,
      title: "Löneadministration",
      description:
        "Förenklar hanteringen av löner och säkerställer korrekta löneutbetalningar.",
      details:
        "LD Ekonomi & Konsult förenklar och automatiserar lönehantering för att eliminera manuella misstag.",
      category: "Payroll",
    },
    {
      image: image4,
      title: "Fakturering",
      description:
        "Hjälper dig med faktureringsrutiner och administration för fakturering.",
      details:
        "LD Ekonomi & Konsult hanterar allt från fakturautskick till betalningsspårning, vilket säkerställer ett smidigt kassaflöde.",
      category: "Billing",
    },
    {
      image: image5,
      title: "Årsredovisning",
      description:
        "Hjälper till att förbereda finansiella rapporter och årsredovisningar.",
      details:
        "LD Ekonomi & Konsult levererar detaljerade och professionella årsredovisningar som stödjer framtida planering.",
      category: "Reports",
    },
    {
      image: image6,
      title: "Leverantörsreskontra",
      description:
        "Hanterar leverantörsreskontra och säkerställer effektiv fakturaflöde.",
      details:
        "LD Ekonomi & Konsult ser till att dina leverantörsbetalningar hanteras effektivt och i rätt tid.",
      category: "Accounting",
    },
  ];

  const toggleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <Box sx={{ margin: "auto", textAlign: "center", paddingTop: "100px" }}>
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#00264d",
          marginBottom: "40px",
          width: {
            xs: "95%",
            md: "90%",
          },
          textAlign: {
            xs: "left",
            md: "center",
          },
          marginInline: "auto",
        }}
      >
        LD Ekonomi & Konsult Exklusiva Tjänst
      </Typography>

      <Box
        sx={{
          maxWidth: "800px",
          margin: "auto",
          padding: "40px 20px",
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // Single column on small devices
            sm: "1fr 1fr", // Two columns on medium devices
            md: "repeat(3, 1fr)", // Three columns on larger devices
          },
          gridAutoFlow: "row dense", // Enables placing items intelligently
          gap: "20px",
        }}
      >
        {services.map((service, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              backgroundColor: "white",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.03)",
              },
              marginTop: {
                sm:
                  index % 2 === 1 &&
                  Math.ceil((index + 1) / 2) < Math.ceil(services.length / 2) // Check if it's a middle row
                    ? "30px"
                    : "0",
                md:
                  index % 3 === 1 &&
                  Math.ceil((index + 1) / 3) < Math.ceil(services.length / 3) // Check if it's a middle row
                    ? "50px"
                    : "0",
              },
            }}
          >
            {/* Image Section */}
            <Box
              sx={{
                height: "200px",
                backgroundImage: `url(${service.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></Box>

            {/* Content Section */}
            <Box sx={{ padding: "20px" }}>
              {/* Category */}
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: "#ffc107",
                  color: "#00264d",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  display: "inline-block",
                  marginBottom: "10px",
                  minWidth: "100px",
                }}
              >
                {service.category}
              </Typography>

              {/* Title */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#00264d",
                  marginBottom: "10px",
                }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                sx={{
                  color: "gray",
                  marginBottom: "10px",
                  textAlign: "left",
                }}
              >
                {service.description}
              </Typography>

              {/* Expandable Details */}
              {expandedIndex === index && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "gray",
                    marginBottom: "10px",
                    textAlign: "left",
                  }}
                >
                  {service.details}
                </Typography>
              )}

              {/* Toggle Button */}
              <Button
                onClick={() => toggleExpand(index)}
                sx={{
                  color: "#ffc107",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: 0,
                  textTransform: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {expandedIndex === index ? "Visa mindre" : "Läs mer"}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ServicesOnPage;
