import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { FaCogs, FaDollarSign, FaUsers } from "react-icons/fa";

function ServicesContact() {
  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "auto",
        textAlign: "center",
        padding: "40px 20px",
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          width: {
            xs: "95%",
            md: "90%",
          },
          textAlign: {
            xs: "left",
            md: "center",
          },
          marginInline: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#ffc107",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Vi tar ansvar i vårt arbete
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: "#071324",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Tjänster som ingår i varje paket{" "}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#555",
            marginBottom: "40px",
          }}
        >
          Vi går på djupet för att upptäcka insikter och har modet att agera. Vi
          samarbetar med dig för att utmana etablerade tankesätt och driva
          förändring.
        </Typography>
      </Box>
      {/* Services Cards */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" },
          gap: "20px",
        }}
      >
        {/* Service 1 */}
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#f4f8fb",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80px",
              width: "80px",
              borderRadius: "50%",
              backgroundColor: "white",
              border: "2px solid #ffc107",
              margin: "0 auto",
            }}
          >
            <FaCogs size={32} color="#071324" />
          </Box>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                color: "#071324",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Bokföring{" "}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#555",
                marginBottom: "20px",
                textAlign: {
                  xs: "left",
                },
              }}
            >
              Vi hjälper dig att hantera din bokföring och säkerställer att allt
              sköts korrekt enligt svenska regler.
            </Typography>
          </CardContent>
        </Card>

        {/* Service 2 */}
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#f4f8fb",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80px",
              width: "80px",
              borderRadius: "50%",
              backgroundColor: "white",
              border: "2px solid #ffc107",
              margin: "0 auto",
            }}
          >
            <FaDollarSign size={32} color="#071324" />
          </Box>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                color: "#071324",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Prissättning{" "}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#555",
                marginBottom: "20px",
                textAlign: {
                  xs: "left",
                },
              }}
            >
              Vi erbjuder transparenta och tydliga prissättningar som passar
              dina behov och din verksamhet.
            </Typography>
          </CardContent>
        </Card>

        {/* Service 3 */}
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#fcfeff",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80px",
              width: "80px",
              borderRadius: "50%",
              backgroundColor: "white",
              border: "2px solid #ffc107",
              margin: "0 auto",
            }}
          >
            <FaUsers size={32} color="#071324" />
          </Box>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                color: "#071324",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Rådgivning
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#555",
                marginBottom: "20px",
                textAlign: {
                  xs: "left",
                },
              }}
            >
              Vi ger dig professionell rådgivning för att fatta rätt ekonomiska
              beslut och optimera din verksamhet.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default ServicesContact;
