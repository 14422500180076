import React from "react";
import { Box, Typography } from "@mui/material";
import {
  FaFileAlt,
  FaMoneyBillAlt,
  FaChartBar,
  FaCalculator,
  FaFileInvoice,
  FaListAlt,
} from "react-icons/fa";
import { useInView } from "react-intersection-observer"; // For viewport detection

function ServicesHome() {
  const services = [
    {
      icon: <FaCalculator size={30} color="#ffc107" />,
      title: "Löpande bokföring",
      description:
        "Hjälper dig med löpande bokföring och säkerställer en bra rutin för rapportering.",
    },
    {
      icon: <FaFileAlt size={30} color="#ffc107" />,
      title: "Skattedeklaration",
      description:
        "Hanterar alla typer av deklarationer och säkerställer att de sköts korrekt.",
    },
    {
      icon: <FaMoneyBillAlt size={30} color="#ffc107" />,
      title: "Löneadministration",
      description:
        "Förenklar hanteringen av löner och säkerställer korrekta löneutbetalningar.",
    },
    {
      icon: <FaFileInvoice size={30} color="#ffc107" />,
      title: "Fakturering",
      description:
        "Hjälper dig med faktureringsrutiner och administration för fakturering.",
    },
    {
      icon: <FaChartBar size={30} color="#ffc107" />,
      title: "Årsredovisning",
      description:
        "Hjälper till att förbereda finansiella rapporter och årsredovisningar.",
    },
    {
      icon: <FaListAlt size={30} color="#ffc107" />,
      title: "Leverantörsreskontra",
      description:
        "Hanterar leverantörsreskontra och säkerställer effektiv fakturaflöde.",
    },
  ];

  // Intersection Observer Hook
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Start animation when 10% of the element is visible
  });

  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "auto",
        textAlign: "center",
        py: 8,
        px: 2,
      }}
    >
      {/* Subtitle */}
      <Typography
        variant="subtitle2"
        sx={{
          color: "#ffc107",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        Våra tjänster
      </Typography>

      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          mt: 1,
          color: "#00264d",
          width: {
            xs: "95%",
            md: "90%",
          },
          textAlign: {
            xs: "left",
            md: "center",
          },
          marginInline: "auto",
        }}
      >
        Vi levererar varje gång, utan undantag.
      </Typography>

      {/* Cards Container */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: "20px",
          mt: 6,
        }}
        ref={ref} // Add reference for Intersection Observer
      >
        {services.map((service, index) => (
          <Box
            key={index}
            sx={{
              marginInline: "auto",
              flex: {
                xs: "1 1 calc(50% - 10px)",
                sm: "1 1 calc(40% - 10px)",
                md: "1 1 calc(30% - 10px)", // 3 cards per row for medium screens
              },
              maxWidth: {
                xs: "calc(80%)",
                sm: "calc(50%)",
                md: "calc(25%)",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s, opacity 0.3s",
              opacity: inView ? 1 : 0, // Fade effect
              transform: inView
                ? `translateY(0px)` // Normal position when in view
                : `translateY(50px)`, // Slightly below the screen when out of view
              transitionDelay: `${index * 0.2}s`, // Staggered effect
              "&:hover": {
                transform: "scale(1.05)",
              },

              backgroundColor: "white",
              height: "250px",
            }}
          >
            {/* Icon */}
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "2px solid #ffc107",
                backgroundColor: "rgba(255, 193, 7, 0.1)",
              }}
            >
              {service.icon}
            </Box>

            {/* Title */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mt: 1,
                color: "#00264d",
              }}
            >
              {service.title}
            </Typography>

            {/* Description */}
            <Typography
              variant="body2"
              sx={{
                color: "gray",
                mt: 1,
                textAlign: "left",
              }}
            >
              {service.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ServicesHome;
