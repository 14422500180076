import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import image1 from "../../assets/about1.webp";
import image2 from "../../assets/about2.webp";
import image3 from "../../assets/about3.webp";
import { Link } from "react-router-dom";

// Array of imported images
const images = [image1, image2, image3];

function HeroAbout() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "70vh",
        overflow: "hidden",
      }}
    >
      {/* Slider Images */}
      {images.map((image, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "opacity 1s ease-in-out",
            opacity: index === currentIndex ? 1 : 0,
          }}
        ></Box>
      ))}

      {/* Overlay Color */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(11, 17, 24, 0.6)", // Semi-transparent black overlay
        }}
      ></Box>

      {/* Text Content */}
      <Box
        sx={{
          position: "absolute",
          width: "80%",
          margin: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "left",
          color: "white",
          padding: "0 20px", // Add padding for smaller screens
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#ffc107",
            fontWeight: "bold",
            fontSize: {
              xs: "32px", // Smaller size for extra small screens
              // Medium size for small screens
              md: "48px", // Default size for medium screens and above
            },
            textTransform: "uppercase",
            fontfamily: '"Montserrat", serif',
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
          }}
        >
          LD Ekonomi & Konsult
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "22px", // Smaller size for extra small screens
              md: "38px", // Default size for medium screens and above
            },
            textTransform: "uppercase",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            marginBottom: "20px",
          }}
        >
          Innovativa lösningar för företag att blomstra
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            maxWidth: "800px",
            // margin: "auto",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "22px",
            },
          }}
        >
          Vi stärker företag med skräddarsydda finansiella strategier och
          innovativa lösningar för hållbar tillväxt och framgång.
        </Typography>
        <Button
          component={Link}
          to="/contact"
          variant="contained"
          onClick={() => window.scrollTo(0, 0)}
          sx={{
            mt: 4,
            backgroundColor: "#ffc107", // Yellow color
            color: "black",
            fontWeight: "bold",
            fontSize: {
              xs: "12px", // Smaller button text for extra small screens
              md: "14px", // Default size for medium screens and above
            },
            "&:hover": {
              backgroundColor: "#e0a800",
            },
          }}
        >
          Kontakta oss →
        </Button>
      </Box>
    </Box>
  );
}

export default HeroAbout;
