import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useInView } from "react-intersection-observer"; // For viewport detection
import backgroundImage from "../../assets/5.webp";
import { Link } from "react-router-dom";

function Purpose() {
  // Intersection Observer Hook
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Start animation when 10% of the element is visible
  });

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "50vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
      ref={ref} // Add reference for Intersection Observer
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(11, 17, 24, 0.8)", // Semi-transparent dark overlay
        }}
      ></Box>

      {/* Content */}
      <Box
        sx={{
          width: {
            xs: "80%",
            md: "60%",
          },
          position: "absolute",
          top: "50%",
          left: "50%",
          textAlign: "center",
          color: "white",
          padding: "0 20px",
          opacity: inView ? 1 : 0, // Fade effect
          transform: inView
            ? "translate(-50%, -50%)" // Normal position when in view
            : "translate(-50%, -60%)", // Slightly above when out of view
          transition: "opacity 0.8s ease, transform 0.8s ease", // Smooth fade and slide transition
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "24px", // Smaller font size for extra small screens
              sm: "32px", // Medium font size for small screens
              md: "40px", // Default size for medium screens and above
            },
            mb: 3,
            width: {
              xs: "95%",
              md: "90%",
            },
            textAlign: {
              xs: "left",
              md: "center",
            },
            marginInline: "auto",
          }}
        >
          Syftet är att leverera excellens i service och utförande.
        </Typography>
        <Button
          component={Link} // Use Link as the underlying component
          to="/services"
          onClick={() => window.scrollTo(0, 0)}
          variant="contained"
          sx={{
            mt: 4,
            backgroundColor: "#ffc107", // Yellow color
            color: "black",
            fontWeight: "bold",
            fontSize: {
              xs: "12px", // Smaller button text for extra small screens
              md: "14px", // Default size for medium screens and above
            },
            "&:hover": {
              backgroundColor: "#e0a800",
            },
          }}
        >
          Utforska tjänster →
        </Button>
      </Box>
    </Box>
  );
}

export default Purpose;
