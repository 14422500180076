import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function ContactHome() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.2, // Start animation when 20% of the component is visible
  });

  return (
    <Box
      ref={ref}
      sx={{
        maxWidth: "800px",
        textAlign: "left",
        py: "100px",
        px: 2,
        backgroundColor: "#fff",
        margin: "auto",
        alignContent: "center",
        opacity: inView ? 1 : 0,
        transform: inView ? "scale(1)" : "scale(0.9)",
        transition: "opacity 0.8s ease, transform 0.8s ease",
      }}
    >
      {/* Company Tagline */}
      <Box
        sx={{
          width: {
            xs: "95%",
            md: "90%",
          },
          textAlign: {
            xs: "left",
            md: "center",
          },
          marginInline: "auto",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#ffc107", // Yellow color
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Skaffa LD Ekonomi & Konsult idag!
        </Typography>

        {/* Heading */}
        <Typography
          variant="h5"
          sx={{
            color: "#00264d", // Dark blue color
            fontWeight: "bold",

            mb: 4,
          }}
        >
          Vertikala lösningar för ditt företag för att säkerställa smidiga och
          framgångsrika verksamheter.{" "}
        </Typography>

        {/* Contact Button */}
        <Button
          component={Link} // Use Link as the underlying component
          to="/contact"
          onClick={() => window.scrollTo(0, 0)}
          variant="contained"
          sx={{
            mt: 4,
            backgroundColor: "#ffc107", // Yellow color
            color: "black",
            fontWeight: "bold",
            fontSize: {
              xs: "12px", // Smaller button text for extra small screens
              md: "14px", // Default size for medium screens and above
            },
            "&:hover": {
              backgroundColor: "#e0a800",
            },
          }}
        >
          Kontakta oss →
        </Button>
      </Box>
    </Box>
  );
}

export default ContactHome;
